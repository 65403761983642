import { navigate } from "gatsby-link"
import React, { useEffect } from "react"
import Layout from "../components/Layout/Layout"

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/")
  }, [])
  return <Layout></Layout>
}

export default NotFoundPage
